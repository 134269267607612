import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Grid, Link, Paper, useMediaQuery, IconButton } from '@mui/material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import logo from './logo.png'; 
import InstagramIcon from '@mui/icons-material/Instagram';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Tilt from 'react-parallax-tilt';
import { LinkedIn, YouTube } from '@mui/icons-material';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#D4AF37',
    },
    background: {
      default: '#fff',
      paper: 'rgba(255, 255, 255, 0.7)',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    fontFamily: 'Ivy Presto, serif',
    h6: {
      color: '#333333'
    },
    h4: {
      color: '#333333', 
    },
    body1: {
      color: '#333333',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#D4AF37',
    },
    background: {
      default: '#333333',
      paper: 'rgba(0, 0, 0, 0.8)', 
    },
    text: {
      primary: '#fff',
    },
  },
  typography: {
    fontFamily: 'Ivy Presto, serif',
    h6: {
      color: '#fff'
    },
    h4: {
      color: '#fff',
    },
    body1: {
      color: '#fff',
    },
  },
});

const MaintenancePage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleThemeToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box sx={{ minHeight: '100vh', backgroundColor: 'transparent' }}>
        <IconButton onClick={handleThemeToggle} sx={{ zIndex: '999', position: 'fixed', top: 16, right: 16, color: '#D4AF37' }}>
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <Container
          maxWidth="lg"
          sx={{
            width: '90vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: 'auto',
            padding: 2,
            borderRadius: 0,
            color: theme.palette.text.primary,
            position: 'relative',
            zIndex: 1,
            boxShadow: ' 0 0 10px 5px rgba(0, 0, 0, 0.1),  0 0 20px 15px rgba(0, 0, 0, 0.05),0 0 30px 25px rgba(0, 0, 0, 0.02)', /* Even wider shadow */

          }}
          component={Paper}
          elevation={3}
        >
          <Tilt
            tiltMaxAngleX={5}
            tiltMaxAngleY={5}
            perspective={1000}
            scale={1.05}
            transitionSpeed={2000}
            gyroscope={true}
            style={{ width: '90%', paddingBottom: '20px' }}
          >
            <img src={logo} alt="Deseo Media Company Logo" style={{ marginTop: '20px', width: 'auto', height: isSmallScreen ? '35vh' : '50vh', borderRadius: '50%' }} />
          </Tilt>
          <Typography variant="h4" gutterBottom>
            We are under maintenance
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you for your patience. Please contact us here and reach out to our social media platforms.
          </Typography>
         
    <Box 
      component="form" 
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      sx={{ mt: 3, width: '100%' }} 
      noValidate 
      autoComplete="off"
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" /> 

      <TextField
        fullWidth
        id="name"
        label="Name"
        name="name"
        variant="outlined"
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        id="email"
        label="Email"
        name="email"
        variant="outlined"
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        id="message"
        label="Message"
        name="message" 
        variant="outlined"
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: '#D4AF37',
          color: '#333333',
          '&:hover': {
            backgroundColor: '#333333',
            color: '#fff',
          },
        }}
      >
        Submit
      </Button>
    </Box>
          <Box sx={{ mt: 5, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Follow us on social media
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Link href="https://www.youtube.com/channel/UCYSl9Jj70dRmadJk87hJABQ" target="_blank" rel="noopener">
                  <Button
                    variant="contained"
                    startIcon={<YouTube />}
                    sx={{
                      backgroundColor: '#D4AF37',
                      color: '#333333',
                      '&:hover': {
                        backgroundColor: '#333333',
                        color: '#fff',
                      },
                    }}
                  >
                    Youtube
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://linkedin.com/company/deseo-media-company" target="_blank" rel="noopener">
                  <Button
                    variant="contained"
                    startIcon={<LinkedIn />}
                    sx={{
                      backgroundColor: '#D4AF37',
                      color: '#333333',
                      '&:hover': {
                        backgroundColor: '#333333',
                        color: '#fff',
                      },
                    }}
                  >
                    LinkedIn
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://instagram.com/deseomedia" target="_blank" rel="noopener">
                  <Button
                    variant="contained"
                    startIcon={<InstagramIcon />}
                    sx={{
                      backgroundColor: '#D4AF37',
                      color: '#333333',
                      '&:hover': {
                        backgroundColor: '#333333',
                        color: '#fff',
                      },
                    }}
                  >
                    Instagram
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default MaintenancePage;
