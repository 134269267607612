import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MaintenancePage from './MaintenancePage';
import Spline from '@splinetool/react-spline';
import LoadingScreen from './LoadingScreen';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import earth from './earthdeseo.spline';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Deseo Media Company - Photography, Videography, and Podcast Productions. Hampton Roads, Virginia</title>
        <meta name="description" content="Deseo Media Company is currently undergoing maintenance. Please check back soon." />
        <meta name="keywords" content="Deseo Media, maintenance, media production, web development, digital marketing" />
        <meta name="author" content="Deseo Media Company" />

        <meta property="og:title" content="Deseo Media Company - Maintenance" />
        <meta property="og:description" content="Deseo Media Company is currently undergoing maintenance. Please check back soon." />
        <meta property="og:url" content="https://deseomediacompany.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Deseo Media Company - Maintenance" />
        <meta name="twitter:description" content="Deseo Media Company is currently undergoing maintenance. Please check back soon." />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Box sx={{ position: 'relative', height: '250vh', background: 'black' }}>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <Spline
              scene={earth}
              style={{ zIndex: '1', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}
            />
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center' }}>
              <Router>
                <Routes>
                  <Route path="/maintenance" element={<MaintenancePage />} />
                  <Route path="*" element={<Navigate to="/maintenance" />} />
                </Routes>
              </Router>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default App;
